import Modal from 'react-bootstrap/Modal';
import close from "./assets/BTN_Schliessen.png";
import { useQuery, gql} from '@apollo/client';
import { useTranslation } from 'react-i18next';

const GET_AES_TOURS= gql`
    query MyQuery($objectId: Int!, $lang: String!) {
        tours(id: $objectId, language: $lang) {
            id
            tour_1_text
            tour_2_text
            tour_3_text
            tour_4_text
            tour_5_text
            tour_6_text
            tour_1_title
            tour_2_title
            tour_3_title
            tour_4_title
            tour_5_title
            tour_6_title
        }
    }
`;
const AESTourModalApollo = ({showModal, closeModal, tourId}) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const { loading, error, data, refetch } = useQuery(GET_AES_TOURS,{
        variables:  {objectId : 31, lang: lang},
    });
    const textFields = {
        0: 'tour_1_text',
        1: 'tour_2_text',
        2: 'tour_3_text',
        3: 'tour_6_text',
        4: 'tour_4_text',
        5: 'tour_5_text',
    };
    const titleFields = {
        0: 'tour_1_title',
        1: 'tour_2_title',
        2: 'tour_3_title',
        3: 'tour_6_title',
        4: 'tour_4_title',
        5: 'tour_5_title',
    };
    const handleLanguageSwitch = (newLang) => {
        refetch({ lang: newLang });
    };
    i18n.on('languageChanged', handleLanguageSwitch);


    console.log(tourId);
    const titleToShow = data?.tours[titleFields[tourId]];
    const textToShow = data?.tours[textFields[tourId]];

    return(
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Body>
                <div className="modal-text-title">{titleToShow}</div>
                <div className="modal-text-text">{textToShow}</div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <img src={close} alt="close" onClick={closeModal}/>
            </Modal.Footer>
        </Modal>
    );
}
export default AESTourModalApollo;
